<template>
  <div class="column-frais-similation mr-3">
    <div
      class="default-color-statue "
      :class="{
        'black-color-statue': item['statue'] == 'Créé',
        'red-color': item['statue'] == 'Simulation',
        'green-color': item['statue'] == 'Payé',
        'red-color montant-bare': item['statue'] == 'Annulée'
      }"
    >
      <EditableInputSimulationTh
        :editable="editable"
        :champName="column"
        :item="item"
        :value="item[column]"
        type="text"
        :updateFunction="updateFunction"
        :valueToShow="
          coef == true ? columnCoef : showCoef == true ? columnCoef : column
        "
        :showIconEdit="true"
        :showBlock="showBlock"
        :uniteMontant="uniteMontant"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
    column: { required: true },
    updateFunction: { required: true },
    coef: { default: false },
    editable: { default: true },
    showCoef: { default: false },
    columnCoef: { default: false },
    showBlock: { default: false },
    uniteMontant: { required: false }
  },
  components: {
    EditableInputSimulationTh :  () => import('./EditableInputSimulationTh.vue')
  }
};
</script>

<style lang="scss" scoped>
.column-frais-similation {
  display: flex;
}
</style>
