import { render, staticRenderFns } from "./columnFraisSimulation.vue?vue&type=template&id=822d339c&scoped=true"
import script from "./columnFraisSimulation.vue?vue&type=script&lang=js"
export * from "./columnFraisSimulation.vue?vue&type=script&lang=js"
import style0 from "./columnFraisSimulation.vue?vue&type=style&index=0&id=822d339c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "822d339c",
  null
  
)

export default component.exports